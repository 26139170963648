.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.MuiTypography-root {
    font-family: "Lucida Console", Courier, monospace;
}

.MuiTypography-h4 {
    font-family: "Lucida Console", Courier, monospace;

}

@font-face {
    font-family: 'sophia';
    font-style: normal;

    font-size: large;
    src: url(./fonts/SofiaPro-Bold.otf);

}

@font-face {
    font-family: 'sophia-light';
    font-style: normal;

    font-size: large;
    src: url(./fonts/SofiaPro-UltraLight.otf);

}

@font-face {
    font-family: 'sophia-regular';
    font-style: normal;

    font-size: large;
    src: url(./fonts/SofiaPro-UltraLight.otf);

}

@font-face {
    font-family: 'sophia-medium';
    font-style: normal;

    font-size: large;
    src: url(./fonts/SofiaPro-UltraLight.otf);

}

@media (max-Width: 450px) {
        .FormGroup {
            width: 85%;
            margin-left: -4% !important;
        
        }
}

.FormGroup {
    margin: 0 15px 10px;
    
    padding-top: 25px;
    padding-bottom: 25px;
    border-style: none;
    background-color: #fbfbfb;
    will-change: opacity, transform;
    /* box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), */
      /* inset 0 1px 0 #829fff; */
    border-radius: 4px;
    display: block;


   
  }
  
  .FormRow {
    /* display: -ms-flexbox; */
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    display: block;
    /* border: 1px solid #f28f16; */
  }
  
  .StripeElement--webkit-autofill {
    background: transparent !important;
  }
  
  .StripeElement {
    width: 100%;
    /* padding: 11px 15px 11px 0;k */
    border-bottom: 1px solid #b5b1b1;
    padding-bottom: 5px;
  }

  .InputElement {
      border-bottom: 1px solid #6b6a6a;
  }


  .MuiInputBase-root {
      display: inline;
  }


  .MuiOutlinedInputLabel-root.Mui-focused .MuiOutlinedInputLabel-notchedOutline {
    border-color: #f28f16 !important;
    border-width: 2px;
  
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #f28f16 !important;
    border-width: 2px;
  
  }
  .MuiInputLabel-outlined.MuiInputLabel {
    color: #f28f16 !important;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #f28f16 !important;
  }

  .MuiTypography-root {
      font-family: 'sophia';
  }
  



.MuiOutlinedInput-root {
    border-radius: 0px !important;
}

.MuiFilledInput-underline:before {
    display: none !important;
}

.MuiFilledInput-underline:after {
    display: none !important;
}
.MuiFilledInput-inputMarginDense {
    padding-top: 0px !important;
}
.MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
    display: none !important;
}
.MuiFilledInput-root {
    border-radius: 6px;
    background-color: #f5f5f5 !important;
}
.iconsGrid {
    text-align: -webkit-auto !important;
}



.makeStyles-imgStyle-17:hover {
    transform: scale(1.1);

}


.makeStyles-imgStyle-17 {
    /* padding: 50px; */
    /* background-color: green; */
    transition: transform .6s; /* Animation */
    /* width: 200px; */
    /* height: 200px; */
    margin: 0 auto;
}

.makeStyles-imgStyle-4:hover {
    transform: scale(1.1);

}


.makeStyles-imgStyle-4 {
    /* padding: 50px; */
    /* background-color: green; */
    transition: transform .6s; /* Animation */
    /* width: 200px; */
    /* height: 200px; */
    margin: 0 auto;
}

.MuiCardActionArea-root {
    width: 100%;
    display: block;
    text-align: inherit;
    color: #fff !important;
}


/* Loader Button Style */

.MuiButton-text {
    padding-top: 10px !important;
    padding-bottom:  10px !important;
    background-color: #FCB040 !important;
}

.MuiButton-text:hover {

    background-color: #f2a026 !important;

}

/* Loader Button Style Ends Here */


.MuiButton-root:hover  {


    
    background-color: '#BF3604',
}

/* underline removed from categories menu */

.MuiLink-underlineHover:hover {
  color: #303030;
    text-decoration: none !important;
}

.MuiLink-underlineHover {
    color: #303030 !important;
}


/* Removed padding around category menu */
.MuiExpansionPanelDetails-root {
    padding: 0px !important;
}





/* underline removed from hover menu */
.MuiButtonBase-root {
    text-decoration: none !important;
}



.makeStyles-listItemStyle-77 {
    min-height: 40px !important;
    max-height: 40px !important;
    box-shadow: none !important;
}

.MuiExpansionPanelSummary-root.Mui-expanded {
    max-height: 50px !important;
    min-height: 50px !important;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}




/* single product crousal style  */

.carousel .slider-wrapper {
    border-radius: 6px !important;
}

.carousel .thumb.selected, .carousel .thumb:hover {
    border: 3px solid #FCB040 !important;
    border-radius: 6px !important;
}





/* offer table alignment */
.MuiTableCell-alignRight {
    text-align: left !important;
}

.MuiTableSortLabel-iconDirectionAsc {
    display: none !important;
}



.MuiIconButton-root:hover {
    background-color: transparent !important;
    
}



/* cart List */
/* .MuiTableContainer-root {
    padding-left: 3%;
    padding-right: 3%;
} */


.MuiListItemSecondaryAction-root {
    font-size: 16px !important;
   
}

.MuiBadge-colorSecondary {
    background-color: #c13100 !important;
}

.makeStyles-listItemStyle-55 {
   margin: 0px !important
}

.MuiBadge-anchorOriginTopRightRectangle {
    position:  -webkit-sticky !important;
}

.MuiSvgIcon-root.makeStyles-iconStyle-29:hover {
    color: #f28f16 !important;

}

.makeStyles-iconBtn-25:hover {
    background-color: #fcb040 !important;
}

@media (max-width: 1024px) {
    .makeStyles-msgsComponent-30 {
        width: 966% !important;
        transform: scaleY(-1);
        margin-top: 45%;
        max-height: 400px;
        margin-left: 50px !important;
    }

    .makeStyles-msgGrid {
        margin-left: -8% !important;
    }

    .MuiListItem-gutters {
        padding-left: 25px !important;
    }

    .MuiOutlinedInput-inputAdornedEnd {
        padding-left: 0px !important;
    }

    .mobileMsgList {
        width: 70% !important;
    }

    .makeStyles-msgGrid-32 {
        padding-left: 70px !important;
    }

  
  }


@media (max-width: 480px) {
    .makeStyles-msgsComponent-30 {
        width: 472% !important;
        transform: scaleY(-1);
        margin-top: 43% !important;
        max-height: 400px;
        margin-left: 50px !important;
    }

    .MuiListItem-gutters {
        padding-left: 5px !important;
    }

    .makeStyles-msgGrid-32 {
        padding-left: 35px !important;
    }
    .MuiListItemAvatar-root {
        min-width: 35px !important;

    }

    .MuiGrid-grid-xs-12 {
        max-width: 120% !important;
    }

    /* .makeStyles-mobileMsgList {
        width: 70% !important;
    } */

   
  }


  /* .mobileMsgList {
    width: 70% !important;
} */


  /* @media (max-Width: 1920px) {
    .mobileMsgList {
        width: 100% !important;  
    }
  } */


  .autoloader:hover {
                        color: #f28f16 !important;
  }

  /* .MuiBadge-anchorOriginTopRightRectangle {
   
    position: absolute !important;
    z-index: 1 ;
} */
/* 
.badge {
    z-index: 1 !important;
    position: -webkit-sticky !important;
}

.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-invisible {
    transform: scale(1) translate(50%, -50%) !important;
} */

.quotestyle {
    /* background: #f9f9f9; */
    /* border-left: 10px solid #ccc; */
    margin: 1.5em 10px;
    /* padding: 0.5em 10px; */
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    quotes: "\201C""\201D""\2018""\2019";
    font-family: sophia-regular !important;
    font-style: italic !important;
    font-size: 1.1rem !important;
  }
  .quotestyle:before {
    color: #ccc;
    content: open-quote;
    font-size: 6em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
  .quotestyle p {
    display: inline;
  }

  .MuiTypography-body1 {
    font-size: 1rem;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
    font-family: sophia-regular !important;
    font-weight: 600 !important;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.MuiTableCell-root {
 
    font-family: sophia-light !important;
    line-height: 2,0 !important;
    color: '#565252' !important;
    font-size: '0.9rem' !important;
    letter-spacing: 0.8 !important;
    font-weight: 1000 !important;
    

}

.MuiTableRow-root {

    font-family: sophia !important;
    line-height: 2,0 !important;
    color: '#565252' !important;
    font-size: '1.0rem' !important;
    letter-spacing: 0.8 !important;
    font-weight: 1000 !important;

}
.MuiTab-textColorPrimary.Mui-selected {
    color: #303030 !important;
}

.PrivateTabIndicator-colorPrimary-90 {
    background-color: #f28f16 !important;
}


/* .MuiTypography-displayBlock {
    font-family: sophia-light !important;
    line-height: 2.0 !important;
    color: #565252 !important;
    font-size: 1rem !important;
    letter-spacing: 0.8 !important;
    font-weight: 1000 !important;
} */


.MuiPaper-elevation8 {
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0), 0px 3px 14px 2px rgba(0,0,0,0) !important;
    border: 1px solid #cecccc !important;
}







.headingquote {
	font-weight: 100;
	font-size: 1rem;
	/* max-width: 600px; */
	line-height: 1.4;
	position: relative;
	margin: 0;
	padding: .1rem;
}

.headingquote:before,
.headingquote:after {
	position: absolute;
	color: #f28f16;
	font-size: 8rem;
	width: 4rem;
	height: 4rem;
}

@media (max-width: 600px) {
    .headingquote:before {
         
            position: absolute;
            color: #f28f16;
            font-size: 4rem;
            width: 2rem;
            height: 2rem;
            margin-top: 15px;
        }
    
    .headingquote:after {
        position: absolute;
        color: #f28f16;
        font-size: 4rem;
        width: 2rem;
        height: 2rem;
    }
  }

.headingquote:before {
	content: '“';
	left: -0.7rem;
	top: -2rem;
}

.headingquote:after {
	content: '”';
    /* right: 20rem; */
    
	bottom: 0.4rem;
}

cite {
	line-height: 3;
	text-align: left;
}


.MuiBottomNavigationAction-root.Mui-selected {
    color: #f28f16 !important;
    padding-top: 6px;
}



.MuiTab-wrapper {

    font-family: sophia-regular !important;
    
    font-Weight: 1000 !important;
    font-Size: 0.9rem !important;
}



.cardservice{
    
        box-shadow: 0 20px 20px rgba(0,0,0,0.14) !important;
        -webkit-box-shadow: 0 20px 20px rgba(0,0,0,0.14) !important;
    
}

.cardgrid:hover {
    margin-top: -40px !important;
    transition: all 0.3s ease-out 0s;
}

.cardgrid1:hover {
    margin-top: -40px !important;
    transition: all 0.3s ease-out 0s;
}



 /* .prefix__cls-3 {
    fill: #303030 !important;
}

.prefix__cls-3 {
    fill: #303030 !important;
} */







@media (max-width: 600px) {
    .react-player {
      width: 150% !important;
      margin-left: -25% !important;
    }
  }


  @media (max-width: 480px) {
    .react-player {
      width: 115% !important;
      margin-left: -8% !important;
    }
  }



  .MuiBottomNavigationAction-label {
      color: #494949 !important;
      font-size: 0.9rem !important;
  }




  .searchicon {
    background-color: #fcb040 !important;
    border-top-right-radius: 0.4rem !important;
    border-bottom-right-radius: 0.4rem !important;
    border-top-left-radius: 0.2rem !important;
    Border-bottom-left-radius: 0.2rem !important;
    border: 2px solid #fcb040 !important;
    margin-right: -3% !important;
    z-index: 1 !important;
  }

  .searchicon:hover {
    background-color: #faab34 !important;
    border-radius: 6 !important;
    margin-right: -3% !important;
    color: #fff !important;
  }
 

  .PrivateTabIndicator-colorPrimary-113 {
    background-color: #f28f16;
}

.MuiTabs-flexContainer {
    background-color: #fbfbfb;
    width: 386px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: -5px !important;
    
}

.MuiTabs-root {
    z-index: 1;

}




.stickyitem {
    position: -webkit-sticky;
    position: sticky;
    top: 93%;
  width: 371px !important;
  background-color: #fbfbfb;
  
}



div.fixedquote {

    position: fixed;
    top: 47%;
    width: 371;
    background-color: #fbfbfb;
    
}
div.fixedmsg {
    position: fixed;
    top: 47%;
    width: 371;
    background-color: #fbfbfb
    
  }

  .MuiTabs-flexContainer {
    display: flex;
    width: 370px;
}

.longdescription {
    display: block;
    unicode-bidi: embed;
    
    word-break: break-word !important;
    /* word-wrap: break-word; */
   
    white-space: break-spaces;
    font-family: sophia-regular;
    font-Size: 1.25rem;
    font-weight: 500;
    text-align: justify;
    text-align-last: auto;
    /* width: 540px !important; */
}

.MuiButton-contained:active {
    box-shadow: none;
}


.MuiOutlinedInput-root {
  
    border-radius: 5px !important;
}

.lastitem:hover {
    background-color: transparent !important;
}



.makeStyles-msgText-28 {
    border-top-left-radius: 8px;
}



@media (min-width: 360px){
    .labelStyle {
       display: none;
      }
    }


/* .labelStyle {
    margin-bottom: -30px !important;
} */




/* for responsive tables */

/*
	Max width before this PARTICULAR table gets nasty. This query will take effect for any screen smaller than 760px and also iPads specifically.
    */
    
    @media (max-width: 760px){
    .responsivetable {
        border-collapse: collapse;
        
      }
    }

      @media (max-width: 760px){
      
       th, td {
        border: 1px solid #efefef;
        padding: 10px;
      }
    }
          @media
            only screen 
          and (max-width: 760px), (min-device-width: 768px) 
          and (max-device-width: 1024px)
            {
      
              /* Force table to not be like tables anymore */

              @media (max-Width: 760px) {
              table, thead, tbody, th, td, tr {
                  display: block !important;
              }
              }
      
              /* Hide table headers (but not display: none;, for accessibility) */
              thead tr {
                  position: absolute !important;
                  top: -9999px !important;
                  left: -9999px !important;
              }
              
      
          tr {
            margin: 0 0 3rem 0;
            
          }
            
          tr:nth-child(odd) {
            background: #fff !important;
          }
          


          tbody {
              margin-bottom: 10px !important;
          }
          
              td {
                  /* Behave  like a "row" */
                  /* border: none; */
                  border-bottom: 1px solid #eee;
                  position: relative !important;
                  padding-left: 55% !important;
                  /* width: 100% !important; */
                  /* margin-bottom: 2rem !important;
                  margin-top: 2rem !important; */
                 
                  
              }


              .wishlist td {
                  width: 100% !important;
              }

              .mainGrid td {
                width: 100% !important;
            }
                    .orderDiv td {
                        width: 100% !important;
                    }
              .deleteIcon {
                  margin-left: -10px !important;
              }

              
      
              td:before {
                  /* Now like a table header */
                  position: absolute !important;
                  /* Top/left values mimic padding */
                  top: 45 !important;
                  left: 6px;
                  width: 45 !important;
                  padding-right: 10px;
                  white-space: nowrap !important;
                  font-size: 16px !important;
                  font-weight: 600;
                  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
              }

              

              #input-with-icon-textfield {
                  margin-left: 15px !important;
              }


              .MuiSelect-selectMenu {
                  white-space: pre-line !important;
              }


              /* background color for tables on mobile */

              .mainGrid table {
                    background-color: #fff !important;
              }

            

              
              .orderDiv table {
                background-color: #fff !important;
          }

          .wishlist table {
            background-color: #fff !important;
      }

      .wishlist tr {
        overflow-x: hidden !important;
  }
      
              /*
              Label the data
          You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
              */


              .td:nth-of-type(1):before { content: "Quote ID"; }
              .td:nth-of-type(2):before { content: "No. Of Products"; }
              .td:nth-of-type(3):before { content: "Image"; }
              .td:nth-of-type(4):before { content: "No. Of Cartons"; }
              .td:nth-of-type(5):before { content: "Size (g)"; }
              .td:nth-of-type(6):before { content: "Action"; }

            
              .cartdata:nth-of-type(1):before { content: "Product ID"; }
              .cartdata:nth-of-type(2):before { content: "Description"; }
              .cartdata:nth-of-type(3):before { content: "Image"; }
              .cartdata:nth-of-type(4):before { content: "No. Of Cartons"; }
              .cartdata:nth-of-type(5):before { content: "Size (g)"; }
              .cartdata:nth-of-type(6):before { content: "Action"; }



              .singleorder:nth-of-type(1):before { content: "Product ID"; }
              .singleorder:nth-of-type(2):before { content: "Product Description"; }
              .singleorder:nth-of-type(3):before { content: "Image"; }
              .singleorder:nth-of-type(4):before { content: "No. Of Cartons"; }
              .singleorder:nth-of-type(5):before { content: "Price Per Carton"; }
              .singleorder:nth-of-type(6):before { content: "Discount"; }
              .singleorder:nth-of-type(7):before { content: "Subtotal"; }


              .singlequote:nth-of-type(1):before { content: "Product ID"; }
              .singlequote:nth-of-type(2):before { content: "Description"; }
              .singlequote:nth-of-type(3):before { content: "Image"; }
              .singlequote:nth-of-type(4):before { content: "Size"; }
              .singlequote:nth-of-type(5):before { content: "No. Of Cartons"; }
              .singlequote:nth-of-type(6):before { content: "Starting Price"; }
              .singlequote:nth-of-type(7):before { content: "Discount"; }
              .singlequote:nth-of-type(8):before { content: "Sub Total"; }




              .quotesdata:nth-of-type(1):before { content: "Quote ID"; margin-left: -150px; margin-right: 50px; }
              .quotesdata:nth-of-type(2):before { content: "No. Of items"; margin-left: -150px; margin-right: 50px; }
              .quotesdata:nth-of-type(3):before { content: "Date & Time"; margin-left: -150px; margin-right: 50px; }


              .mainGrid td:nth-of-type(1):before { content: "Quote ID"; }
              .mainGrid td:nth-of-type(2):before { content: "No. Of Products"; } 
              .mainGrid td:nth-of-type(3):before { content: "Date & Time"; }
              .mainGrid td:nth-of-type(4):before { content: "Status"; } 
              .mainGrid td:nth-of-type(5):before { content: "Action"; }


              .orderDiv td:nth-of-type(1):before { content: "Order ID"; }
              .orderDiv td:nth-of-type(2):before { content: "Date & Time"; } 
              .orderDiv td:nth-of-type(3):before { content: "Products"; } 
              .orderDiv td:nth-of-type(4):before { content: "order Value"; }
              .orderDiv td:nth-of-type(5):before { content: "Status"; } 
              .orderDiv td:nth-of-type(6):before { content: "Action"; }


              .wishlist td:nth-of-type(1):before { content: "Product ID"; }
              .wishlist td:nth-of-type(2):before { content: "Image"; } 
              .wishlist td:nth-of-type(3):before { content: "Starting Price"; } 
              .wishlist td:nth-of-type(4):before { content: "Actions"; } 
              

/*  .orderDiv td:nth-of-type(3):before { content: "Starting Price"; } 
              @media (min-width: 760px){
                .quotesdata {
                    display: none !important;
                    
                    
                  }
                } */

              }




            


              
          
        


         